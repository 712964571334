import validateComponent from '@/router/middleware/validateComponent';
import { dashboardContainer } from '../containers';

const DICTIONARY_DASHBOARD = {
  name: 'Dashboard',
  vocabularies: ['dashboard', 'projects', 'companies', 'tenders', 'notes', 'activities', 'search'],
};

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard');

export default [
  dashboardContainer({
    meta: {
      breadcrumb: { name: 'dashboard' },
      menuId: 100,
    },
    path: '/dashboard',
    children: [
      {
        meta: {
          component: 'dshbrd',
          middleware: [validateComponent],
          breadcrumb: { name: 'dashboard' },
          dict: DICTIONARY_DASHBOARD,
          menuId: 101,
        },
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      },
    ],
  }),
];
