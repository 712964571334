import leadsRoutes from './leads';
import salesRoutes from './sales';
import myranLogsRoutes from './myran-logs';
import onboardingRoutes from './onboarding';
import apiSettingsRoutes from './api-settings';
import supportRoutes from './support';
import systemRoutes from './system';
import usageStatsRoutes from './usage-stats';
import pagesRoutes from './pages';
import vortalConnectRoutes from './vortal-connect';
import logsRoutes from './logs';
import parabygMigrationRoutes from './parabyg-migration';
import dashboardRoutes from './dashboard';

export default [
  ...leadsRoutes,
  ...salesRoutes,
  ...myranLogsRoutes,
  ...onboardingRoutes,
  ...apiSettingsRoutes,
  ...supportRoutes,
  ...logsRoutes,
  ...systemRoutes,
  ...usageStatsRoutes,
  ...vortalConnectRoutes,
  ...pagesRoutes,
  ...parabygMigrationRoutes,
  ...dashboardRoutes,
];
