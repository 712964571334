import validateComponent from '@/router/middleware/validateComponent';

export default [
  {
    path: 'components',
    redirect: '/system/components',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          component: 'system_components',
          middleware: [validateComponent],
          breadcrumb: { name: 'Components' },
          menuId: 38,
          dict: {
            name: 'SystemComponents',
            vocabularies: ['system_components'],
          },
        },
        path: '/system/components',
        name: 'SystemComponents',
        component: () => import(
          /* webpackChunkName: "system-components" */ '@/views/system/components/ListComponents'
        ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit component', dynamic: true },
          component: 'system_components',
          middleware: [validateComponent],
          parentMenuId: 38,
          dict: {
            name: 'SystemComponents',
            vocabularies: ['system_components'],
          },
        },
        path: ':id/edit',
        name: 'SystemEditorComponent',
        component: () => import(
          /* webpackChunkName: "system-component-editor" */ '@/views/system/components/EditorComponent'
        ),
      },
      {
        meta: {
          breadcrumb: { name: 'Create component' },
          component: 'system_components',
          middleware: [validateComponent],
          parentMenuId: 38,
          dict: {
            name: 'SystemComponents',
            vocabularies: ['system_components'],
          },
        },
        path: 'new',
        name: 'SystemEditorComponentNew',
        component: () => import(
          /* webpackChunkName: "system-component-editor" */ '@/views/system/components/EditorComponent'
        ),
      },
    ],
  },
];
