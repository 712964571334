import generalRoutes from './general';
import dashboardRoutes from './dashboard';
import { dashboardContainer } from '@/router/containers';

export default [
  dashboardContainer({
    meta: {
      component: 'usage_stats_clients',
      breadcrumb: { name: 'usage_statistics' },
      menuId: 51,
    },
    path: '/usage-statistics',
    redirect: '/usage-statistics/clients',
    name: 'UsageStatistics',
    children: [...generalRoutes, ...dashboardRoutes],
  }),
];
