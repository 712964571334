import validateComponent from '@/router/middleware/validateComponent';

const DICTIONARY_SYSTEM_DEFAULT_DASHBOARD_WIDGETS = {
  name: 'SystemDefaultDashboardWidgets',
  vocabularies: [
    'dashboard',
    'default_dashboard_widgets',
    'projects',
    'companies',
    'tenders',
  ],
};

export default [
  {
    path: 'default-dashboard-widgets',
    redirect: '/system/default-dashboard-widgets',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        meta: {
          breadcrumb: { name: 'Default Dashboard Widgets' },
          component: 'default_dashboard_widgets',
          middleware: [validateComponent],
          menuId: 94,
          dict: DICTIONARY_SYSTEM_DEFAULT_DASHBOARD_WIDGETS,
        },
        path: '/system/default-dashboard-widgets',
        name: 'DefaultDashboardWidgets',
        component: () =>
          import(
            /* webpackChunkName: "default-dashboard-widgets" */ '@/views/system/default-dashboard-widgets/DefaultDashboardWidgets'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Edit default dashboard widgets', dynamic: true },
          component: 'default_dashboard_widgets',
          middleware: [validateComponent],
          parentMenuId: 97,
          dict: DICTIONARY_SYSTEM_DEFAULT_DASHBOARD_WIDGETS,
        },
        path: ':id/edit',
        name: 'DefaultDashboardWidgetsEdit',
        component: () =>
          import(
            /* webpackChunkName: "default-dashboard-widgets" */ '@/views/system/default-dashboard-widgets/EditorDefaultDashboardWidgets.vue'
          ),
      },
      {
        meta: {
          breadcrumb: { name: 'Create default dashboard widgets', dynamic: true },
          component: 'default_dashboard_widgets',
          middleware: [validateComponent],
          parentMenuId: 97,
          dict: DICTIONARY_SYSTEM_DEFAULT_DASHBOARD_WIDGETS,
        },
        path: 'new',
        name: 'DefaultDashboardWidgetsNew',
        component: () =>
          import(
            /* webpackChunkName: "default-dashboard-widgets" */ '@/views/system/default-dashboard-widgets/EditorDefaultDashboardWidgets.vue'
          ),
      },
    ],
  },
];
