import validateComponent from '@/router/middleware/validateComponent';

export default [
  {
    path: 'clients-widgets',
    redirect: '/usage-statistics/clients-widgets',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/usage-statistics/clients-widgets',
        name: 'UsageStatisticsClientsWidgets',
        component: () =>
          import(
            /* webpackChunkName: "clients-dashboard" */ '@/views/usage-statistics/dashboard-stats/clients/ClientsStats.vue'
          ),
        meta: {
          component: 'usage_stats_clients_widgets',
          middleware: [validateComponent],
          breadcrumb: { name: 'Organizations widgets' },
          dict: {
            vocabularies: ['dashboard', 'usage_stats_widgets', 'usage_stats', 'clients'],
          },
        },
      },
      {
        path: ':id',
        name: 'UsageStatisticsClientWidgets',
        component: () =>
          import(
            /* webpackChunkName: "usage-stats-client" */ '@/views/usage-statistics/dashboard-stats/users/ClientUsersStats.vue'
          ),
        meta: {
          component: 'usage_stats_clients_widgets',
          middleware: [validateComponent],
          breadcrumb: { name: 'Organization widgets' },
          dict: {
            vocabularies: ['search', 'usage_stats', 'users', 'dashboard', 'usage_stats_widgets'],
          },
        },
      },
    ],
  },
  {
    path: 'users-widgets',
    redirect: '/usage-statistics/users-widgets',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/usage-statistics/users-widgets',
        name: 'UsageStatisticsUsersWidgets',
        component: () =>
          import(
            /* webpackChunkName: "usage-stats-users" */ '@/views/usage-statistics/dashboard-stats/users/UsersStats.vue'
          ),
        meta: {
          component: 'usage_stats_users_widgets',
          middleware: [validateComponent],
          breadcrumb: { name: 'Users widgets' },
          dict: {
            vocabularies: ['search', 'usage_stats', 'users', 'dashboard', 'usage_stats_widgets'],
          },
        },
      },
      {
        path: ':id',
        name: 'UsageStatisticsUserWidgets',
        component: () =>
          import(
            /* webpackChunkName: "usage-stats-user" */ '@/views/usage-statistics/dashboard-stats/users/UserStats.vue'
          ),
        meta: {
          component: 'usage_stats_users_widgets',
          middleware: [validateComponent],
          breadcrumb: { name: 'User widgets' },
          dict: {
            vocabularies: ['dashboard', 'usage_stats', 'users', 'usage_stats_widgets'],
          },
        },
      },
    ],
  },
];
